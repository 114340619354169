.app {
  line-height: 1.5;
  display: flex;
  display: -ms-flexbox;
  &-container {
    display: flex;
    display: -ms-flexbox;
    flex: 1 auto;
    flex-direction: column;
    max-width: 100%;
    position: relative;
    min-height: 100vh;
  }
}
.navigation-wrapper {
  width: 100%;
  background-color: rgba(3, 14, 32, 0.72);
}
.content-container {
  background-color: #fff;
}
/*=============================================
=            모바일            =
=============================================*/
@media screen and (max-width: 1439px) {
  html {
    min-width: 380px;
  }

  .app-container {
    background-image: url("../img/common/background.png");
    background-position-x: center;
    background-repeat: no-repeat;
    // background-size: 100%;
  }
  .desk {
    display: none !important;
  }

  .container {
    max-width: 380px;
    min-height: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .non-height-container {
    max-width: 380px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .home-title-text {
    margin-top: 100px;
  }
  .home-title-text2 {
    margin-bottom: 300px;
  }

  #header {
    max-width: 100%;
    .navigation {
      line-height: 64px;
      &-container {
        .navbar {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .dropdown {
          line-height: 26px;
          .select-language {
            color: #ffffff !important;
            border: 2px solid #fff;
            border-radius: 10px;
            padding: 0 15px;
            a {
              color: #fff !important;
            }
            .ant-dropdown-link {
              font-size: 10px;
              margin-right: 40px;
            }
            .anticon.anticon-down {
              margin: 0px;
            }
          }
        }
        .drawer {
          .ant-menu-inline {
            border-right: 0;
          }
          .ant-menu-item-selected {
            background-color: transparent;
          }
          .ant-menu-inline .ant-menu-item::after {
            border-right: 0px;
          }
        }
      }
    }
    .header-intro {
      // padding-top: 84px;
      min-height: 630px;
      section {
        margin: 30px 0;
        .title {
          margin-bottom: 30px;
        }
        p {
          font-family: "Poppins";
          font-size: 12px;
        }
        .more-btn {
          width: 111px;
          height: 30px;
          background-color: aquamarine;
          border-radius: 30px;
          margin: 10px auto;
          span {
            font-size: 13px;
            color: #000;
            letter-spacing: 1.56px;
          }
        }
      }
      &-icon-wrap {
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: -25px;
        }
      }
    }
    .header-coin {
      height: 471px;
      &__title {
        margin-top: 86px;
        margin-bottom: 20px;
        p {
          font-size: 20px;
          font-weight: normal;
        }
      }
      &__body {
        line-height: 2;
        span {
          font-size: 12px;
        }
      }
    }
    .header-board {
      min-height: 147px;
      &__title {
        span {
          font-size: 22px;
        }
      }
      &__body {
        span {
          font-size: 12px;
        }
      }
    }
  }

  .header-blobscode-img {
    width: 300px;
  }
  .title-text {
    font-size: 36px;
    line-height: 54px;
    color: #fff;
    padding-top: 30px;
  }
  .subtitle-text {
    font-size: 16px;
    color: #fff;
    margin-top: 15px;
  }
  .button-white {
    font-size: 12px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 3px 15px;
    display: inline-block;
    cursor: pointer;
  }
  .button-white:hover {
    color: #044a92;
    background-color: #fff;
  }
  .content-title-text {
    font-size: 16px;
    color: #000;
    background-image: url("../img/icon/bullet_blue.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 16px;
    padding: 25px 0px;
  }
  .content-title-text-white {
    font-size: 16px;
    color: #fff;
    background-image: url("../img/icon/bullet_blue.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 16px;
    padding: 25px 0px;
  }
  .content-line {
    height: 1px;
    background-color: #d7d7d7;
  }
  .content-line-blue {
    height: 1px;
    background-color: #2a4e8a;
  }
  .content-summary-text {
    font-size: 20px;
    color: #04509b;
    line-height: 26px;
    padding: 25px 0px;
  }
  .content-text {
    font-size: 12px;
  }
  .content-text-white {
    font-size: 12px;
    color: #fff;
  }
  .content-image {
    padding: 20px 0px;
    text-align: center;
  }
  .page-top-image-wrapper {
    position: fixed;
    bottom: 20px;
    right: 10px;
  }
  .page-top-image {
    width: 50px;
  }

  .board {
    padding: 20px 0;
    &-item {
      border-radius: 11px;
      border: 1px solid #000000;
      margin-bottom: 15px;
      width: 100%;
    }
    .question {
      padding: 12.5px 0;
      &-wrap {
        margin: 0 14.8px;
        .icon {
          border-right: 1px solid #000;
          padding-right: 11.5px;
          margin-right: 11.5px;
        }
        .article {
          list-style: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 14px;
          }
        }
      }
    }
    .answer {
      border-top: 1px solid #000;
      padding-left: 4.5%;
      padding-right: 18px;
      padding-top: 17px;
      padding-bottom: 20px;
      span {
        line-height: 2.14;
        font-size: 12px;
      }
    }
  }
  .vision-wrapper {
    background-image: url("../img/main/companyinfo/bg-vision.png");
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #location {
    .location {
      // padding: 30px 100px 50px;

      width: 100%;
      &-info {
        padding: 18px;
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 20px;
        line-height: 1.8;
        &-name {
          font-size: 18px !important;
          color: #2a4e8a;
          font-weight: 900;
          margin-bottom: 10px;
        }
        &-address {
          display: flex;
        }
        &-address div {
          width: 75%;
        }
      }
      &-info span {
        display: inline-block;
        font-size: 16px;
        font-weight: 900;
        width: 25%;
      }
      &-info div {
        font-size: 14px;
      }
      &-map {
        height: 350px;
      }
    }
  }

  #footer {
    background-color: #2a2a2a;
    min-height: 400px;
    span {
      color: #fff;
    }
    .footer-container {
      width: 100%;
      height: 380px;

      padding-top: 39px;
      padding-bottom: 45px;
      display: flex;
      flex-direction: column;
      .company-info {
        padding-bottom: 45px;
        .blobs-coin-title {
          color: #4d5261;
          font-size: 16px;
        }
        span {
          font-size: 12px;
        }
      }
      .right {
        .link-img {
          width: 32px;
        }
        .iso-img {
          width: 32px;
        }
        .association-img {
          width: 116px;
        }
      }
    }
  }
}

/*=============================================
=            데스크탑            =
=============================================*/

@media screen and (min-width: 1440px) {
  html {
    min-width: 1440px;
  }

  .app-container {
    background-image: url("../img/common/background.png");
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .mobile {
    display: none !important;
  }

  .container {
    max-width: 1340px;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .non-height-container {
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .home-title-text {
    margin-top: 300px;
  }

  #header {
    max-width: 100%;
    .navigation {
      // position: fixed;
      // z-index: 3;
      line-height: 84px;
      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-list {
        li[role="menuitem"] {
          line-height: 35px;
        }
        .ant-menu-item-active,
        .ant-menu-item-selected,
        .ant-menu-submenu-open,
        .ant-menu-submenu-selected,
        .ant-menu-submenu-title:hover {
          color: #3afdfb !important;
          border-bottom-color: #3afdfb;
        }
        .ant-menu-item {
          a {
            color: #fff !important;
          }
          a:hover {
            color: #3afdfb;
          }
        }
        .select-language {
          color: #ffffff !important;
          border: 2px solid #fff;
          border-radius: 10px;
          a {
            color: #fff !important;
          }
          .ant-dropdown-link {
            margin-right: 40px;
          }
          .anticon.anticon-down {
            margin: 0px;
          }
        }
        li {
          margin-left: 50px;
        }

        &-item {
          text-align: center;
          margin-left: 20px;
          list-style: none;
        }
        &-item a {
          color: white;
          text-decoration: none;
          padding-bottom: 5px;
        }
        &-item :hover {
          border-bottom: 1px solid rgba($color: #3afdfb, $alpha: 0.4);
        }
      }
    }
    .header-intro {
      // padding-top: 84px;
      min-height: 860px;
      section {
        margin: 30px 0;
        .title {
          margin-bottom: 30px;
        }
        p {
          font-family: "Poppins";
          font-size: 20px;
        }
        .more-btn {
          width: 185px;
          height: 50px;
          background-color: aquamarine;
          border-radius: 30px;
          margin: 10px auto;
          span {
            font-size: 22px;
            color: #000;
            letter-spacing: 2.64px;
          }
        }
      }
      &-icon-wrap {
        position: relative;
        img {
          position: absolute;
          left: 33%;
        }
      }
    }
    .header-coin {
      min-height: 900px;
      &__title {
        margin-top: 200px;
        margin-bottom: 130px;
        p {
          font-size: 54px;
          font-weight: normal;
        }
      }
      &__body {
        width: 1000px;
        line-height: 2;
        span {
          font-size: 18px;
        }
      }
    }
    .header-board {
      min-height: 367px;
      &__title {
        span {
          font-size: 36px;
        }
      }
    }
  }
  .header-blobscode-img {
    width: 400px;
  }

  .title-text {
    font-size: 48px;
    line-height: 54px;
    color: #fff;
    padding-top: 80px;
  }
  .subtitle-text {
    font-size: 20px;
    color: #fff;
    margin-top: 35px;
  }
  .button-white {
    font-size: 16px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 3px 15px;
    display: inline-block;
    cursor: pointer;
  }
  .button-white:hover {
    color: #044a92;
    background-color: #fff;
  }
  .content-title-text {
    font-size: 20px;
    color: #000;
    background-image: url("../img/icon/bullet_blue.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 20px;
    padding: 30px 0px;
  }
  .content-title-text-white {
    font-size: 20px;
    color: #fff;
    background-image: url("../img/icon/bullet_blue.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 20px;
    padding: 30px 0px;
  }
  .content-line {
    height: 1px;
    background-color: #d7d7d7;
  }
  .content-line-blue {
    height: 1px;
    background-color: #2a4e8a;
  }
  .content-summary-text {
    font-size: 23px;
    color: #04509b;
    line-height: 30px;
    padding: 30px 0px;
  }
  .content-text {
    font-size: 16px;
  }
  .content-text-white {
    font-size: 16px;
    color: #fff;
  }
  .content-image {
    padding: 20px 0px;
    text-align: center;
  }
  .page-top-image-wrapper {
    position: fixed;
    bottom: 150px;
    right: 100px;
  }
  .page-top-image {
  }

  .board {
    padding: 30px 0;
    &-item {
      border-radius: 11px;
      border: 1px solid #000000;
      margin-bottom: 15px;
      width: 100%;
      .question {
        padding: 12.5px 0;
        &-wrap {
          margin-left: 23px;
          margin-right: 16px;
          .icon {
            width: 3%;
            border-right: 1px solid #000;
            margin-right: 16.5px;
          }
        }
      }
      .answer {
        border-top: 1px solid #000;
        padding-left: 4.5%;
        padding-right: 50px;
        padding-top: 30px;
        padding-bottom: 20px;
        span {
          line-height: 2.14;
        }
      }
    }
  }
  .vision-wrapper {
    background-image: url("../img/main/companyinfo/bg-vision.png");
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #location {
    .location {
      padding: 20px 100px;
      width: 100%;
      &-info span {
        display: inline-block;
        font-size: 18px;
        font-weight: 900;
        width: 8%;
      }
      &-info div {
        font-size: 18px;
      }
      &-info {
        padding: 20px;
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 20px;
        line-height: 1.7;
        &-name {
          font-size: 22px !important;
          color: #2a4e8a;
          font-weight: 900;
          margin-bottom: 20px;
        }
        &-address {
          display: flex;
        }
        &-address div {
          width: 92%;
        }
      }
      &-map {
        height: 480px;
      }
    }
  }

  #footer {
    background-color: #2a2a2a;
    span {
      color: #fff;
    }
    .footer-container {
      width: 100%;
      min-height: 380px;
      padding-top: 68px;
      display: flex;
      justify-content: space-between;
      .company-info {
        flex: 0.7;
        .blobs-coin-title {
          color: #4d5261;
          font-size: 20px;
        }
      }

      .right {
        flex: 0.3;
      }
    }
  }
}
