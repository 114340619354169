// COMMON

/*display*/

.flex-row {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  align-items: center;
}

.flex-sa {
  justify-content: space-around;
}

.flex-sb {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.flex-1 {
  flex: 1;
  -ms-flex: 1;
}

//text

.t-a-c {
  text-align: center;
}

.white-text {
  color: #ffffff !important;
}

.basic-text {
  color: #000000 !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-48 {
  font-size: 48px !important;
}

.font-50 {
  font-size: 50px !important;
}

.font-bold {
  font-weight: 700;
}

//margin,padding

.m-x-2 {
  margin: 0 8px;
}

.m-y-40 {
  margin: 40px 0px;
}

.m-y-60 {
  margin: 60px 0px;
}

.p-y-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.p-y-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.m-t-3 {
  margin-top: 16px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-b-2 {
  margin-bottom: 8px;
}

.m-b-3 {
  margin-bottom: 16px;
}

.m-b-4 {
  margin-bottom: 24px;
}

.m-b-5 {
  margin-bottom: 32px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-100 {
  margin-bottom: 100px;
}

.m-r-4 {
  margin-right: 24px;
}

.p-b-20 {
  padding-bottom: 20px;
}

//etc

.user-select {
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}
