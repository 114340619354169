@charset "UTF-8";
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
.app {
  line-height: 1.5;
  display: flex;
  display: -ms-flexbox; }
  .app-container {
    display: flex;
    display: -ms-flexbox;
    flex: 1 auto;
    flex-direction: column;
    max-width: 100%;
    position: relative;
    min-height: 100vh; }

.navigation-wrapper {
  width: 100%;
  background-color: rgba(3, 14, 32, 0.72); }

.content-container {
  background-color: #fff; }

/*=============================================
=            모바일            =
=============================================*/
@media screen and (max-width: 1439px) {
  html {
    min-width: 380px; }
  .app-container {
    background-image: url("../img/common/background.png");
    background-position-x: center;
    background-repeat: no-repeat; }
  .desk {
    display: none !important; }
  .container {
    max-width: 380px;
    min-height: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px; }
  .non-height-container {
    max-width: 380px;
    margin: 0 auto;
    padding: 0 20px; }
  .home-title-text {
    margin-top: 100px; }
  .home-title-text2 {
    margin-bottom: 300px; }
  #header {
    max-width: 100%; }
    #header .navigation {
      line-height: 64px; }
      #header .navigation-container .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between; }
      #header .navigation-container .dropdown {
        line-height: 26px; }
        #header .navigation-container .dropdown .select-language {
          color: #ffffff !important;
          border: 2px solid #fff;
          border-radius: 10px;
          padding: 0 15px; }
          #header .navigation-container .dropdown .select-language a {
            color: #fff !important; }
          #header .navigation-container .dropdown .select-language .ant-dropdown-link {
            font-size: 10px;
            margin-right: 40px; }
          #header .navigation-container .dropdown .select-language .anticon.anticon-down {
            margin: 0px; }
      #header .navigation-container .drawer .ant-menu-inline {
        border-right: 0; }
      #header .navigation-container .drawer .ant-menu-item-selected {
        background-color: transparent; }
      #header .navigation-container .drawer .ant-menu-inline .ant-menu-item::after {
        border-right: 0px; }
    #header .header-intro {
      min-height: 630px; }
      #header .header-intro section {
        margin: 30px 0; }
        #header .header-intro section .title {
          margin-bottom: 30px; }
        #header .header-intro section p {
          font-family: "Poppins";
          font-size: 12px; }
        #header .header-intro section .more-btn {
          width: 111px;
          height: 30px;
          background-color: aquamarine;
          border-radius: 30px;
          margin: 10px auto; }
          #header .header-intro section .more-btn span {
            font-size: 13px;
            color: #000;
            letter-spacing: 1.56px; }
      #header .header-intro-icon-wrap {
        position: relative; }
        #header .header-intro-icon-wrap img {
          position: absolute;
          top: 0;
          left: -25px; }
    #header .header-coin {
      height: 471px; }
      #header .header-coin__title {
        margin-top: 86px;
        margin-bottom: 20px; }
        #header .header-coin__title p {
          font-size: 20px;
          font-weight: normal; }
      #header .header-coin__body {
        line-height: 2; }
        #header .header-coin__body span {
          font-size: 12px; }
    #header .header-board {
      min-height: 147px; }
      #header .header-board__title span {
        font-size: 22px; }
      #header .header-board__body span {
        font-size: 12px; }
  .header-blobscode-img {
    width: 300px; }
  .title-text {
    font-size: 36px;
    line-height: 54px;
    color: #fff;
    padding-top: 30px; }
  .subtitle-text {
    font-size: 16px;
    color: #fff;
    margin-top: 15px; }
  .button-white {
    font-size: 12px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 3px 15px;
    display: inline-block;
    cursor: pointer; }
  .button-white:hover {
    color: #044a92;
    background-color: #fff; }
  .content-title-text {
    font-size: 16px;
    color: #000;
    background-image: url("../img/icon/bullet_blue.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 16px;
    padding: 25px 0px; }
  .content-title-text-white {
    font-size: 16px;
    color: #fff;
    background-image: url("../img/icon/bullet_blue.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 16px;
    padding: 25px 0px; }
  .content-line {
    height: 1px;
    background-color: #d7d7d7; }
  .content-line-blue {
    height: 1px;
    background-color: #2a4e8a; }
  .content-summary-text {
    font-size: 20px;
    color: #04509b;
    line-height: 26px;
    padding: 25px 0px; }
  .content-text {
    font-size: 12px; }
  .content-text-white {
    font-size: 12px;
    color: #fff; }
  .content-image {
    padding: 20px 0px;
    text-align: center; }
  .page-top-image-wrapper {
    position: fixed;
    bottom: 20px;
    right: 10px; }
  .page-top-image {
    width: 50px; }
  .board {
    padding: 20px 0; }
    .board-item {
      border-radius: 11px;
      border: 1px solid #000000;
      margin-bottom: 15px;
      width: 100%; }
    .board .question {
      padding: 12.5px 0; }
      .board .question-wrap {
        margin: 0 14.8px; }
        .board .question-wrap .icon {
          border-right: 1px solid #000;
          padding-right: 11.5px;
          margin-right: 11.5px; }
        .board .question-wrap .article {
          list-style: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          .board .question-wrap .article span {
            font-size: 14px; }
    .board .answer {
      border-top: 1px solid #000;
      padding-left: 4.5%;
      padding-right: 18px;
      padding-top: 17px;
      padding-bottom: 20px; }
      .board .answer span {
        line-height: 2.14;
        font-size: 12px; }
  .vision-wrapper {
    background-image: url("../img/main/companyinfo/bg-vision.png");
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover; }
  #location .location {
    width: 100%; }
    #location .location-info {
      padding: 18px;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 20px;
      line-height: 1.8; }
      #location .location-info-name {
        font-size: 18px !important;
        color: #2a4e8a;
        font-weight: 900;
        margin-bottom: 10px; }
      #location .location-info-address {
        display: flex; }
      #location .location-info-address div {
        width: 75%; }
    #location .location-info span {
      display: inline-block;
      font-size: 16px;
      font-weight: 900;
      width: 25%; }
    #location .location-info div {
      font-size: 14px; }
    #location .location-map {
      height: 350px; }
  #footer {
    background-color: #2a2a2a;
    min-height: 400px; }
    #footer span {
      color: #fff; }
    #footer .footer-container {
      width: 100%;
      height: 380px;
      padding-top: 39px;
      padding-bottom: 45px;
      display: flex;
      flex-direction: column; }
      #footer .footer-container .company-info {
        padding-bottom: 45px; }
        #footer .footer-container .company-info .blobs-coin-title {
          color: #4d5261;
          font-size: 16px; }
        #footer .footer-container .company-info span {
          font-size: 12px; }
      #footer .footer-container .right .link-img {
        width: 32px; }
      #footer .footer-container .right .iso-img {
        width: 32px; }
      #footer .footer-container .right .association-img {
        width: 116px; } }

/*=============================================
=            데스크탑            =
=============================================*/
@media screen and (min-width: 1440px) {
  html {
    min-width: 1440px; }
  .app-container {
    background-image: url("../img/common/background.png");
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: 100%; }
  .mobile {
    display: none !important; }
  .container {
    max-width: 1340px;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px; }
  .non-height-container {
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 20px; }
  .home-title-text {
    margin-top: 300px; }
  #header {
    max-width: 100%; }
    #header .navigation {
      line-height: 84px; }
      #header .navigation-container {
        display: flex;
        align-items: center;
        justify-content: space-between; }
      #header .navigation-list li[role="menuitem"] {
        line-height: 35px; }
      #header .navigation-list .ant-menu-item-active,
      #header .navigation-list .ant-menu-item-selected,
      #header .navigation-list .ant-menu-submenu-open,
      #header .navigation-list .ant-menu-submenu-selected,
      #header .navigation-list .ant-menu-submenu-title:hover {
        color: #3afdfb !important;
        border-bottom-color: #3afdfb; }
      #header .navigation-list .ant-menu-item a {
        color: #fff !important; }
      #header .navigation-list .ant-menu-item a:hover {
        color: #3afdfb; }
      #header .navigation-list .select-language {
        color: #ffffff !important;
        border: 2px solid #fff;
        border-radius: 10px; }
        #header .navigation-list .select-language a {
          color: #fff !important; }
        #header .navigation-list .select-language .ant-dropdown-link {
          margin-right: 40px; }
        #header .navigation-list .select-language .anticon.anticon-down {
          margin: 0px; }
      #header .navigation-list li {
        margin-left: 50px; }
      #header .navigation-list-item {
        text-align: center;
        margin-left: 20px;
        list-style: none; }
      #header .navigation-list-item a {
        color: white;
        text-decoration: none;
        padding-bottom: 5px; }
      #header .navigation-list-item :hover {
        border-bottom: 1px solid rgba(58, 253, 251, 0.4); }
    #header .header-intro {
      min-height: 860px; }
      #header .header-intro section {
        margin: 30px 0; }
        #header .header-intro section .title {
          margin-bottom: 30px; }
        #header .header-intro section p {
          font-family: "Poppins";
          font-size: 20px; }
        #header .header-intro section .more-btn {
          width: 185px;
          height: 50px;
          background-color: aquamarine;
          border-radius: 30px;
          margin: 10px auto; }
          #header .header-intro section .more-btn span {
            font-size: 22px;
            color: #000;
            letter-spacing: 2.64px; }
      #header .header-intro-icon-wrap {
        position: relative; }
        #header .header-intro-icon-wrap img {
          position: absolute;
          left: 33%; }
    #header .header-coin {
      min-height: 900px; }
      #header .header-coin__title {
        margin-top: 200px;
        margin-bottom: 130px; }
        #header .header-coin__title p {
          font-size: 54px;
          font-weight: normal; }
      #header .header-coin__body {
        width: 1000px;
        line-height: 2; }
        #header .header-coin__body span {
          font-size: 18px; }
    #header .header-board {
      min-height: 367px; }
      #header .header-board__title span {
        font-size: 36px; }
  .header-blobscode-img {
    width: 400px; }
  .title-text {
    font-size: 48px;
    line-height: 54px;
    color: #fff;
    padding-top: 80px; }
  .subtitle-text {
    font-size: 20px;
    color: #fff;
    margin-top: 35px; }
  .button-white {
    font-size: 16px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 3px 15px;
    display: inline-block;
    cursor: pointer; }
  .button-white:hover {
    color: #044a92;
    background-color: #fff; }
  .content-title-text {
    font-size: 20px;
    color: #000;
    background-image: url("../img/icon/bullet_blue.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 20px;
    padding: 30px 0px; }
  .content-title-text-white {
    font-size: 20px;
    color: #fff;
    background-image: url("../img/icon/bullet_blue.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 20px;
    padding: 30px 0px; }
  .content-line {
    height: 1px;
    background-color: #d7d7d7; }
  .content-line-blue {
    height: 1px;
    background-color: #2a4e8a; }
  .content-summary-text {
    font-size: 23px;
    color: #04509b;
    line-height: 30px;
    padding: 30px 0px; }
  .content-text {
    font-size: 16px; }
  .content-text-white {
    font-size: 16px;
    color: #fff; }
  .content-image {
    padding: 20px 0px;
    text-align: center; }
  .page-top-image-wrapper {
    position: fixed;
    bottom: 150px;
    right: 100px; }
  .board {
    padding: 30px 0; }
    .board-item {
      border-radius: 11px;
      border: 1px solid #000000;
      margin-bottom: 15px;
      width: 100%; }
      .board-item .question {
        padding: 12.5px 0; }
        .board-item .question-wrap {
          margin-left: 23px;
          margin-right: 16px; }
          .board-item .question-wrap .icon {
            width: 3%;
            border-right: 1px solid #000;
            margin-right: 16.5px; }
      .board-item .answer {
        border-top: 1px solid #000;
        padding-left: 4.5%;
        padding-right: 50px;
        padding-top: 30px;
        padding-bottom: 20px; }
        .board-item .answer span {
          line-height: 2.14; }
  .vision-wrapper {
    background-image: url("../img/main/companyinfo/bg-vision.png");
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover; }
  #location .location {
    padding: 20px 100px;
    width: 100%; }
    #location .location-info span {
      display: inline-block;
      font-size: 18px;
      font-weight: 900;
      width: 8%; }
    #location .location-info div {
      font-size: 18px; }
    #location .location-info {
      padding: 20px;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 20px;
      line-height: 1.7; }
      #location .location-info-name {
        font-size: 22px !important;
        color: #2a4e8a;
        font-weight: 900;
        margin-bottom: 20px; }
      #location .location-info-address {
        display: flex; }
      #location .location-info-address div {
        width: 92%; }
    #location .location-map {
      height: 480px; }
  #footer {
    background-color: #2a2a2a; }
    #footer span {
      color: #fff; }
    #footer .footer-container {
      width: 100%;
      min-height: 380px;
      padding-top: 68px;
      display: flex;
      justify-content: space-between; }
      #footer .footer-container .company-info {
        flex: 0.7; }
        #footer .footer-container .company-info .blobs-coin-title {
          color: #4d5261;
          font-size: 20px; }
      #footer .footer-container .right {
        flex: 0.3; } }

/*display*/
.flex-row {
  display: flex; }

.flex-column {
  flex-direction: column; }

.flex-center {
  align-items: center; }

.flex-sa {
  justify-content: space-around; }

.flex-sb {
  justify-content: space-between; }

.flex-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.flex-1 {
  flex: 1;
  -ms-flex: 1; }

.t-a-c {
  text-align: center; }

.white-text {
  color: #ffffff !important; }

.basic-text {
  color: #000000 !important; }

.font-20 {
  font-size: 20px !important; }

.font-24 {
  font-size: 24px !important; }

.font-36 {
  font-size: 36px !important; }

.font-48 {
  font-size: 48px !important; }

.font-50 {
  font-size: 50px !important; }

.font-bold {
  font-weight: 700; }

.m-x-2 {
  margin: 0 8px; }

.m-y-40 {
  margin: 40px 0px; }

.m-y-60 {
  margin: 60px 0px; }

.p-y-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.p-y-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.m-t-3 {
  margin-top: 16px; }

.m-t-60 {
  margin-top: 60px; }

.m-b-2 {
  margin-bottom: 8px; }

.m-b-3 {
  margin-bottom: 16px; }

.m-b-4 {
  margin-bottom: 24px; }

.m-b-5 {
  margin-bottom: 32px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-b-100 {
  margin-bottom: 100px; }

.m-r-4 {
  margin-right: 24px; }

.p-b-20 {
  padding-bottom: 20px; }

.user-select {
  user-select: none; }

.pointer {
  cursor: pointer; }

.responsive-img {
  max-width: 100%;
  height: auto; }

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: auto;
  position: relative; }

* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: NanumSquare; }

*,
:after,
:before {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  word-break: keep-all; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

#root {
  height: 100%;
  display: flex;
  flex-direction: column; }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #09d3ac; }
